import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMealDetailsQuery } from "../../api/baseQueries/mealApi/getMealDetails";
import { useUpdateMealMutation } from "../../api/baseQueries/mealApi/updateMeal"; // Import the mutation
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Col, Card, Row, Button } from "react-bootstrap";
import { formatCurrency } from "../../common/utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faThumbsUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AddIMealngredientModal } from "./modal/addMealIngredient";
import DataTableComponent from "../../components/DataTable";
import { EditIngredientModal } from "./modal/editMealIngredient";
import { useRemoveIngredientFromMealMutation } from "../../api/baseQueries/mealApi/removeIngredientFromMeal";
import { DeleteConfirmationModal } from "./modal/deleteConfirmation";
import { AddMealAddonModal } from "./modal/addMealAddon";
import { MealActionConfirmation } from "./modal/mealActionConfirmation";

const MealDetails = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetMealDetailsQuery({ id });
  const meal = data?.data;

  const [updateMeal] = useUpdateMealMutation(); // Initialize the mutation

  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const [showAddonModal, setShowAddonModal] = useState(false);
  const handleShowIngredientModal = () => setShowIngredientModal(true);
  const handleCloseIngredientModal = () => setShowIngredientModal(false);
  const handleShowAddonModal = () => setShowAddonModal(true);
  const handleCloseAddonModal = () => setShowAddonModal(false);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [selectedIngredient, setSelectedIngredient] = useState({
    id: "",
    name: "",
    qtyInGrams: "",
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMealActionModal, setShowMealActionModal] = useState(false);
  const [ingredientToDelete, setIngredientToDelete] = useState(null);
  const [mealAction, setMealAction] = useState(null);

  const [removeIngredientFromMeal] = useRemoveIngredientFromMealMutation();

  const handleEditIngredient = (ingredient) => {
    setSelectedIngredient(ingredient);
    setShowModal(true);
  };

  const handleRemoveIngredient = (ingredient) => {
    setIngredientToDelete(ingredient);
    setShowDeleteModal(true);
  };

  const handleMealAction = (meal, action) => {
    setMealAction({ meal, action });
    setShowMealActionModal(true);
  };

  const handleConfirmMealAction = async () => {
    if (mealAction) {
      await updateMeal({ id: mealAction.meal.id, action: mealAction.action });
      setShowMealActionModal(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (ingredientToDelete) {
      await removeIngredientFromMeal({ id, ingredient: ingredientToDelete.id });
      setShowDeleteModal(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Quantity (grams)",
      selector: (row) => row.qtyInGrams,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleEditIngredient(row)}
            style={{ marginRight: "5px" }} // Add margin-right for spacing
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>{" "}
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => handleRemoveIngredient(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
    },
  ];

  console.log(meal?.dietCategories);

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <Col xs="12">
            <Card className="shadow">
              <Card.Body>
                <div className="d-flex justify-content-end mb-3">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <Link to={`/dashboard/meals/${id}/edit`} className="me-3">
                      <Button variant="primary">Edit Meal</Button>
                    </Link>
                    <Button
                      variant="primary"
                      onClick={handleShowIngredientModal}
                      className="me-3"
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: "5px" }}
                      />
                      Add Ingredient
                    </Button>

                    <Button
                      variant="primary"
                      onClick={handleShowAddonModal}
                      className="me-3"
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: "5px" }}
                      />
                      Add Addon
                    </Button>

                    {(meal?.status === "Pending Approval" ||
                      meal?.status === "Pending QA") && (
                      <Button
                        variant="success"
                        onClick={() =>
                          handleMealAction(
                            meal,
                            meal?.status === "Pending Approval"
                              ? "Approve"
                              : "QA"
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          style={{ marginRight: "5px" }}
                        />
                        {meal?.status === "Pending QA" && "QA Meal"}
                        {meal?.status === "Pending Approval" && "Approve Meal"}
                      </Button>
                    )}
                  </div>
                </div>
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p className="text-danger">Error loading meal details</p>
                ) : (
                  <div>
                    <h2 className="mb-4">{meal.name}</h2>
                    <p className="mb-3">
                      <strong>Description:</strong> <br /> {meal.description}
                    </p>
                    <p className="mb-3">
                      <strong>Calories per serving:</strong> {meal.caloriesPerServing}Kcal
                    </p>
                    <p className="mb-3">
                      <strong>Fat:</strong> {meal.fat}g
                    </p>
                    <p className="mb-3">
                      <strong>Carbs:</strong> {meal.carbs}g
                    </p>
                    <p className="mb-3">
                      <strong>Protein:</strong> {meal.protein}g
                    </p>
                    <p className="mb-3">
                      <strong>Fibre:</strong> {meal.fibre}g
                    </p>
                    <p className="mb-3">
                      <strong>Serving Size:</strong> {meal.servingSize}g per serving
                    </p>
                    <p className="mb-3">
                      <strong>Amount:</strong> {formatCurrency(meal.amount)}
                    </p>
                    <p className="mb-3">
                      <strong>Course:</strong>
                    </p>
                    <div className="mb-3">
                      {meal.mealCourse &&
                        JSON.parse(meal.mealCourse).map((course, index) => (
                          <span key={index} className="badge bg-primary me-2">
                            {course}
                          </span>
                        ))}
                    </div>
                    <p className="mb-3">
                      <strong>Diet Categories:</strong>
                    </p>
                    <div className="mb-3">
                      {meal.dietCategories &&
                        meal?.dietCategories.map((category, index) => (
                          <span key={index} className="badge bg-info me-2">
                            {category.name}
                          </span>
                        ))}
                    </div>
                    <p className="mb-3">
                      <strong>Contains:</strong>
                    </p>
                    <div className="mb-3">
                      {meal.mealCourse &&
                        JSON.parse(meal.contains).map((course, index) => (
                          <span key={index} className="badge bg-danger me-2">
                            {course}
                          </span>
                        ))}
                    </div>
                    <h3 className="mb-3">Ingredients:</h3>
                    <DataTableComponent
                      title=""
                      columns={columns}
                      data={meal ? meal.mealIngredients : []}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
      <AddIMealngredientModal
        show={showIngredientModal}
        handleClose={handleCloseIngredientModal}
        meal={meal}
      />
      <AddMealAddonModal
        show={showAddonModal}
        handleClose={handleCloseAddonModal}
        meal={meal}
      />
      <EditIngredientModal
        show={showModal}
        handleClose={handleCloseModal}
        meal={meal}
        ingredient={selectedIngredient}
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={handleConfirmDelete}
      />
      <MealActionConfirmation
        show={showMealActionModal}
        handleClose={() => setShowMealActionModal(false)}
        meal={meal}
        action={mealAction?.action}
        handleConfirm={handleConfirmMealAction} // Fix function name
      />
    </React.Fragment>
  );
};

export default MealDetails;

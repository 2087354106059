// DataTableComponent.jsx
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const FilterWrapper = styled.div`
  margin-bottom: 20px;
`;

const DataTableComponent = ({ data, columns, title, selectableRows = false }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Ensure filter works correctly with all cases, including when `filterText` is empty.
  const filteredData = data.filter(item =>
    item.name ? item.name.toLowerCase().includes(filterText.toLowerCase()) : true
  );

  return (
    <div>
      <FilterWrapper>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search by ingredient name"
            aria-label="Search by ingredient name"
            aria-describedby="filter-addon"
            value={filterText}
            onChange={e => {
              setFilterText(e.target.value);
              setResetPaginationToggle(!resetPaginationToggle);
            }}
          />
        </InputGroup>
      </FilterWrapper>
      <DataTable
        title={title}
        columns={columns}
        data={filteredData.length > 0 ? filteredData : data} // Display original data if filteredData is empty
        selectableRows={selectableRows}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        pointerOnHover
        striped
        fixedHeader
        responsive
        progressPending={false}
      />
    </div>
  );
};

export default DataTableComponent;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authData: null,
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    unsetAuth: (state) => {
      state.authData = null;
    },
  },
});

export const { unsetAuth } = authSlice.actions;

export default authSlice.reducer;

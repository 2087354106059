import { ingredientApi } from ".";

export const createIngredientAddon = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    createIngredientAddon: builder.mutation({
      query: (payload) => ({
        url: `/addon`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: ["Ingredient Addons"],
    }),
  }),
});

export const { useCreateIngredientAddonMutation } = createIngredientAddon;

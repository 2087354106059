import { axiosBaseQuery } from "../../interceptor/axiosBaseQuery";
import config from "../../../config/config";
import { createApi } from "@reduxjs/toolkit/query/react";

export const dietCategoryApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/diet-categories` }),
  endpoints: () => ({}),
  reducerPath: "dietCategoryApi",
  // keepUnusedDataFor: 30,
  tagTypes: ["Diet Categories"],
});
import React, { useState } from "react";
import { Button, Modal, Form, FormGroup } from "react-bootstrap";
import Select from "react-select";
import { useAddIngredientToMealMutation } from "../../../api/baseQueries/mealApi/addIngredientToMeal";
import { useGetIngredientAddonsQuery } from "../../../api/baseQueries/ingredientApi/getIngredientAddons";

export const AddMealAddonModal = ({ show, handleClose, meal }) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [quantity, setQuantity] = useState("");
  const { data: ingredientsData } = useGetIngredientAddonsQuery();
  const [addIngredientToMeal] = useAddIngredientToMealMutation();

  const handleIngredientChange = (selected) => {
    setSelectedIngredient(selected);
  };

  const handleAddIngredient = async () => {
    if (selectedIngredient && quantity) {
      const payload = {
        ingredient_id: selectedIngredient.value,
        qty_in_grams:  parseFloat(quantity).toFixed(2),
      };

      try {
        await addIngredientToMeal({ id: meal?.id, ...payload }).unwrap();
        console.log("Ingredient added successfully");
      } catch (error) {
        console.error("Failed to add ingredient:", error);
      }


      // Close the modal
      handleClose();
    }
  };

  const ingredients = ingredientsData?.data;

  const mealIngredientsIds = meal && meal.mealIngredients.map((ingredient) => ingredient.id) || [];

  // Filter out ingredients that are not in mealIngredients
  const filteredIngredients = ingredients && ingredients.filter((ingredient) => !mealIngredientsIds.includes(ingredient.id));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", fontSize: '16px' }}>
          Add ingredient to {meal?.name} meal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup controlId="formIngredientName" style={{ marginBottom: "20px" }}>
            <Form.Label>Ingredient Name</Form.Label>
            <Select
              options={
                filteredIngredients
                  ? filteredIngredients.map((ingredient) => ({
                      value: ingredient.id,
                      label: ingredient.name,
                    }))
                  : []
              }
              onChange={(selectedOption) => handleIngredientChange(selectedOption)}
              placeholder="Select Ingredient"
            />
          </FormGroup>
          <FormGroup controlId="formIngredientQty">
            <Form.Label>Quantity (grams)</Form.Label>
            <Form.Control
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddIngredient}>
          Add Ingredient
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

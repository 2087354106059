import React from "react";
import {
  Col,
  Card,
  Row,
  Button,
} from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import DataTableComponent from "../../components/DataTable";
import { formatCurrency } from "../../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useGetOrdersQuery } from "../../api/baseQueries/orderApi/getOrders";
import dayjs from "dayjs"; // for date formatting

export default function Orders() {
  const { data, error, isLoading } = useGetOrdersQuery();
  const orders = data?.data;
  

  const handleView = (order) => {
    // Edit functionality here
  };

  // Define columns based on active order fields
  const columns = [
    {
      name: "Name",
      selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
      sortable: true,
    },
    {
      name: "Order Number",
      selector: (row) => row.orderNumber,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => dayjs(row.subscription.startDate).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => dayjs(row.subscription.endDate).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            variant="outline-primary"
            onClick={() => handleView(row)}
            className="me-2"
          >
            <Link to={`/dashboard/orders/${row.id}`}>
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Orders
              </li>
            </ol>
          </div>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading orders</p>
                ) : (
                  <DataTableComponent
                    title={"Active Orders"}
                    data={orders}
                    columns={columns}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}

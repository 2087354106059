import { ingredientApi } from ".";

export const deleteIngredient = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteIngredient: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}`, 
        method: 'DELETE',
        data: null,
      }),
      invalidatesTags: ["Ingredients"],
    }),
  }),
});

export const { useDeleteIngredientMutation } = deleteIngredient;

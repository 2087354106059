import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import { useGetOrderQuery } from "../../api/baseQueries/orderApi/getOrder"; // Assuming this is your hook
import MainDetails from "./mainDetails";

const OrderDetails = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetOrderQuery({ id });
  const order = data?.data;
  

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        {isLoading ? (
          <p>Loading order details...</p>
        ) : error ? (
          <p>Error loading order details</p>
        ) : (
          order && (
            <div>
              <h2>Order Details for Order #{order.orderNumber}</h2>
              <p><strong>Status:</strong> {order.status}</p>
              {/* Display additional details as needed */}
              <MainDetails order={order} />
            </div>
          )
        )}
      </div>
    </React.Fragment>
  );
};

export default OrderDetails;

import { mealApi } from ".";

export const getMealDetails = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    getMealDetails: builder.query({
      query: ({ id }) => ({
        url: `/${id}`,
      }),
      providesTags: ["Meal"],
    }),
  }),
});

export const { useGetMealDetailsQuery } = getMealDetails;
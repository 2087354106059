import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useCreateIngredientAddonMutation } from "../../../api/baseQueries/ingredientApi/createIngredientAddon";


export default function CreateIngredientAddonModal({ show, handleClose }) {
  const [name, setName] = useState("");
  const [createIngredientAddon] = useCreateIngredientAddonMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createIngredientAddon({ name }).unwrap();
      handleClose(); // Close the modal on success
    } catch (error) {
      console.error("Failed to create ingredient addon:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Ingredient Add-on</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="addonName">
            <Form.Label>Addon Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter addon name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Add Ingredient
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

import { mealApi } from ".";

export const createMeal = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    createMeal: builder.mutation({
      query: (payload) => ({
        url: ``,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: ["Meals"],
    }),
  }),
});

export const { useCreateMealMutation } = createMeal;

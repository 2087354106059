import { mealApi } from ".";

export const deleteMeal = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteMeal: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}`, 
        method: 'DELETE',
        data: null,
      }),
      invalidatesTags: ["Meals"],
    }),
  }),
});

export const { useDeleteMealMutation } = deleteMeal;

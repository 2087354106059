import React, { useState } from "react";
import {
  Col,
  Card,
  Row,
  Button,
  Offcanvas,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import Select from "react-select";
import DataTableComponent from "../../components/DataTable";
import { useGetIngredientsQuery } from "../../api/baseQueries/ingredientApi/getIngredients";
import { useUpdateIngredientMutation } from "../../api/baseQueries/ingredientApi/updateIngredient";
import { useCreateIngredientMutation } from "../../api/baseQueries/ingredientApi/createIngredient";
import { formatCurrency } from "../../common/utils"; // Import the delete confirmation modal
import { useDeleteIngredientMutation } from "../../api/baseQueries/ingredientApi/deleteIngredient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteIngredientConfirmationModal } from "./modal/deleteIngredientConfirmationModal";

export default function Ingredients() {
  const [show, setShow] = useState(false);
  const [unit, setUnit] = useState("g");
  const [selectedFamilies, setSelectedFamilies] = useState([]);
  const [name, setName] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [qtyInGram, setQtyInGram] = useState("");
  const [family, setFamily] = useState("");
  const [isAddOn, setIsAddOn] = useState(false);
  const [currentIngredient, setCurrentIngredient] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIngredientId, setDeleteIngredientId] = useState(null);
  const [deleteIngredient, setDeleteIngredient] = useState(null);

  const { data, error, isLoading } = useGetIngredientsQuery();
  const ingredients = data?.data;

  const [createIngredientMutation] = useCreateIngredientMutation();
  const [updateIngredientMutation] = useUpdateIngredientMutation();
  const [deleteIngredientMutation] = useDeleteIngredientMutation();

  const handleClose = () => {
    setShow(false);
    setCurrentIngredient(null);
    setName("");
    setCostPrice("");
    setQtyInGram("");
    setFamily("");
    setIsAddOn(false);
  };

  const handleShow = () => setShow(true);

  const handleSelect = (eventKey) => {
    setUnit(eventKey);
  };

  const familyOptions = [
    { value: "Berries", label: "Berries" },
    { value: "Celery", label: "Celery" },
    { value: "Diary", label: "Diary" },
    { value: "Egg", label: "Egg" },
    { value: "Fish", label: "Fish" },
    { value: "Fruits", label: "Fruits" },
    { value: "Fungi", label: "Fungi" },
    { value: "Gluten", label: "Gluten" },
    { value: "Latex", label: "Latex" },
    { value: "Lupin", label: "Lupin" },
    { value: "Meat", label: "Meat" },
    { value: "Mollusks", label: "Mollusks" },
    { value: "Mustard", label: "Mustard" },
    { value: "Peanuts", label: "Peanuts" },
    { value: "Pork", label: "Pork" },
    { value: "Seeds", label: "Seeds" },
    { value: "Sesame", label: "Sesame" },
    { value: "Shellfish", label: "Shellfish" },
    { value: "Soy Bean", label: "Soy Bean" },
    { value: "Spices", label: "Spices" },
    { value: "Sulfites", label: "Sulfites" },
    { value: "Tree Nuts", label: "Tree Nuts" },
    { value: "Vegetables", label: "Vegetables" },
    { value: "Wheat", label: "Wheat" }
  ];
  

  const handleFamilyChange = (selectedOptions) => {
    setSelectedFamilies(selectedOptions);
  };

  const handleEdit = (ingredient) => {
    setCurrentIngredient(ingredient);
    setName(ingredient.name);
    setCostPrice(ingredient.costPrice);
    setQtyInGram(ingredient.qtyInGram);

    const preselectedFamilies = JSON.parse(ingredient.family)?.map(
      (family) => ({
        value: family,
        label: family,
      })
    );

    setSelectedFamilies(preselectedFamilies);
    setShow(true);
  };

  const handleDelete = (ingredient) => {
    setDeleteIngredientId(ingredient?.id);
    setDeleteIngredient(ingredient);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteIngredientMutation({ id: deleteIngredientId }).unwrap();
      setShowDeleteModal(false);
      setDeleteIngredientId(null);
    } catch (err) {
      console.error("Failed to delete ingredient:", err);
    }
  };

  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    {
      name: "Cost Price",
      selector: (row) => formatCurrency(row.costPrice),
      sortable: true,
    },
    {
      name: "Quantity in Grams",
      selector: (row) => row.qtyInGram + "g",
      sortable: true,
    },
    {
      name: "Price Per Gram",
      selector: (row) => formatCurrency(row.pricePerGram),
      sortable: true,
    },
    {
      name: "Is Add On",
      selector: (row) => (row.isAddOn ? "Yes" : "No"),
      sortable: true,
    },
    { name: "Status", selector: (row) => row.status, sortable: true },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            variant="outline-primary"
            onClick={() => handleEdit(row)}
            className="me-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button variant="outline-danger" onClick={() => handleDelete(row)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        name,
        cost_price: costPrice,
        qty_in_gram: qtyInGram,
        family: selectedFamilies?.map((option) => option.value),
        // is_add_on: isAddOn,
      };

      if (currentIngredient) {
        await updateIngredientMutation({
          id: currentIngredient.id,
          ...data,
        }).unwrap();
      } else {
        await createIngredientMutation(data).unwrap();
      }

      setName("");
      setCostPrice("");
      setQtyInGram("");
      setFamily("");
      setIsAddOn(false);
      setShow(false);
      setCurrentIngredient(null);
    } catch (err) {
      console.error("Failed to save ingredient:", err);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ingredients
              </li>
            </ol>
          </div>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <Button className="float-end my-3" onClick={handleShow}>
                  Add Ingredient
                </Button>

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading ingredients</p>
                ) : (
                  <DataTableComponent
                    title={"List of Ingredients"}
                    data={ingredients}
                    columns={columns}
                  />
                )}
              </Card.Body>
            </Card>

            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {currentIngredient ? "Edit Ingredient" : "Add New Ingredient"}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formIngredientName">
                    <Form.Label>Ingredient Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter ingredient name"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formCostPrice">
                    <Form.Label>Cost Price</Form.Label>
                    <Form.Control
                      type="number"
                      value={costPrice}
                      onChange={(e) => setCostPrice(e.target.value)}
                      placeholder="Enter cost price"
                      step="0.01"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formQtyInGram">
                    <Form.Label>Quantity</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        value={qtyInGram}
                        onChange={(e) => setQtyInGram(e.target.value)}
                        placeholder="Enter quantity"
                        step="0.01"
                        required
                      />
                      <DropdownButton
                        variant="outline-secondary"
                        title={unit}
                        id="input-group-dropdown-1"
                        align="end"
                        onSelect={handleSelect}
                      >
                        <Dropdown.Item eventKey="g">g</Dropdown.Item>
                        <Dropdown.Item eventKey="mL">mL</Dropdown.Item>
                        <Dropdown.Item eventKey="L">L</Dropdown.Item>
                      </DropdownButton>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFamily">
                    <Form.Label>Allergens</Form.Label>
                    <Select
                      isMulti
                      options={familyOptions}
                      value={selectedFamilies}
                      onChange={handleFamilyChange}
                      placeholder="Select family"
                    />
                  </Form.Group>
                  {/* <Form.Group className="mb-3" controlId="formIsAddOn">
                    <Form.Check
                      type="checkbox"
                      label="Is Add On"
                      checked={isAddOn}
                      onChange={(e) => setIsAddOn(e.target.checked)}
                    />
                  </Form.Group> */}
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
        </Row>

        <DeleteIngredientConfirmationModal
          show={showDeleteModal}
          name={deleteIngredient?.name}
          handleClose={() => setShowDeleteModal(false)}
          handleConfirm={confirmDelete}
        />

        <Footer />
      </div>
    </React.Fragment>
  );
}

import { mealApi } from ".";

export const getMeals = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeals: builder.query({
      query: ({ status, ...params } = {}) => ({
        url: ``,
        method: 'GET',
        params: { status, ...params },
      }),
      providesTags: ["Meals"],
    }),
  }),
});

export const { useGetMealsQuery } = getMeals;

import { ingredientApi } from ".";

export const updateIngredient = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    updateIngredient: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/${id}`, 
        method: 'PUT',
        data: payload,
      }),
      invalidatesTags: ["Ingredients","Ingredient"],
    }),
  }),
});

export const { useUpdateIngredientMutation } = updateIngredient;

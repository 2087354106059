import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const MealActionConfirmation = ({ show, handleClose, handleConfirm, meal, action }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> {action === 'Approve' ? 'Approve' : 'Complete QA for'} {meal?.name} </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to {action === 'Approve' ? 'approve' : 'QA'} this meal?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          {action === 'Approve' ? 'Approve' : 'Complete QA'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { ingredientApi } from ".";

export const removeIngredientFromAddon = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    removeIngredientFromAddon: builder.mutation({
      query: ({ id, ingredient }) => ({
        url: `/${id}/component/${ingredient}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Ingredient"],
    }),
  }),
});

export const { useRemoveIngredientFromAddonMutation } = removeIngredientFromAddon;

import { ingredientApi } from ".";

export const getIngredientDetails = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    getIngredientDetails: builder.query({
      query: ({ id }) => ({
        url: `/${id}`,
      }),
      providesTags: ["Ingredient"],
    }),
  }),
});

export const { useGetIngredientDetailsQuery } = getIngredientDetails;
import React, { useState } from "react";
import { Col, Card, Row, Button, Modal, Form } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import DataTableComponent from "../../components/DataTable";
import { useGetMealsQuery } from "../../api/baseQueries/mealApi/getMeals";
import { useCreateMealMutation } from "../../api/baseQueries/mealApi/createMeal"; // Add this import for createMeal mutation
import { useGetDietCategoriesQuery } from "../../api/baseQueries/dietCategoryApi/getDietCategories";
import Select from "react-select";
import { formatCurrency } from "../../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteMealConfirmationModal } from "./modal/deleteMealConfirmationModal";
import { useDeleteMealMutation } from "../../api/baseQueries/mealApi/deleteIngredient";

const Meals = () => {
  const { data, error, isLoading } = useGetMealsQuery({});
  const meals = data?.data;
  const navigate = useNavigate();

  const { data: dietCategoriesData } = useGetDietCategoriesQuery(); // Fetch diet categories
  const dietCategories = dietCategoriesData?.data?.map(category => ({
    value: category.id,
    label: category.name
  }));

  const [deleteMealMutation] = useDeleteMealMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMealId, setDeleteMealId] = useState(null);
  const [deleteMeal, setDeleteMeal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    serving_size: "",
    calories_per_serving: "",
    meal_course: [],
    diet_category_ids: []
  });

  const [createMeal] = useCreateMealMutation();

  const handleView = (id) => {
    navigate(`/dashboard/meals/${id}`);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMultiSelectChange = (selectedOptions, name) => {
    setFormData({ ...formData, [name]: selectedOptions.map(option => option.value) });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createMeal(formData);
    handleCloseModal();
  };

  const handleDelete = (ingredient) => {
    setDeleteMealId(ingredient?.id);
    setDeleteMeal(ingredient)
    setShowDeleteModal(true);
  };

  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    {
      name: "Calories",
      selector: (row) => row.caloriesPerServing,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => getCategorySpans(row?.dietCategories || []),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => formatCurrency(row?.amount),
      sortable: true,
    },
    {
      name: "Course",
      selector: (row) => getCourseSpans(row?.mealCourse || []),
      sortable: false,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            variant="outline-primary"
            onClick={() => handleView(row.id)}
            className="me-2"
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
          <Button variant="outline-danger" onClick={() => handleDelete(row)}>
          <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];

  const getCourseSpans = (courseString) => {
    const courses = JSON.parse(courseString);
    return courses.map((course, index) => {
      return (
        <span key={index} className="badge bg-primary me-2">
          {course}
        </span>
      );
    });
  };

  const getCategorySpans = (categories) => {
    return categories.map((category, index) => {
      return (
        <span key={index} className="badge bg-info me-2">
          {category?.name === "Weight Gain" && "WG"}
          {category?.name === "Weight Loss" && "WL"}
          {category?.name === "Body Recomposition" && "BR"}
          {category?.name === "Diabetes" && "DB"}
          {category?.name === "Seniors" && "S"}
        </span>
      );
    });
  };

  const confirmDelete = async () => {
    try {
      await deleteMealMutation({ id: deleteMealId }).unwrap();
      setShowDeleteModal(false);
      setDeleteMealId(null);
    } catch (err) {
      console.error("Failed to delete meal:", err);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Meals
              </li>
            </ol>
          </div>
          <Button variant="primary" onClick={handleShowModal}>
            Create Meal
          </Button>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading meals</p>
                ) : (
                  <DataTableComponent
                    title={"List of Meals"}
                    data={meals} // Use the fetched data
                    columns={columns}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formServingSize">
              <Form.Label>Serving Size</Form.Label>
              <Form.Control
                type="number"
                name="serving_size"
                value={formData.serving_size}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCaloriesPerServing">
              <Form.Label>Calories Per Serving</Form.Label>
              <Form.Control
                type="number"
                name="calories_per_serving"
                value={formData.calories_per_serving}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMealCourse">
              <Form.Label>Meal Course</Form.Label>
              <Select
                isMulti
                options={[
                  { value: "Breakfast", label: "Breakfast" },
                  { value: "Lunch", label: "Lunch" },
                  { value: "Dinner", label: "Dinner" },
                  { value: "Soups", label: "Soups" },
                  { value: "Snack", label: "Snack" }
                ]}
                onChange={(selectedOptions) =>
                  handleMultiSelectChange(selectedOptions, "meal_course")
                }
                placeholder="Select Meal Course"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDietCategory">
              <Form.Label>Diet Category</Form.Label>
              <Select
                isMulti
                options={dietCategories}
                onChange={(selectedOptions) =>
                  handleMultiSelectChange(selectedOptions, "diet_category_ids")
                }
                placeholder="Select Diet Category"
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Create Meal
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <DeleteMealConfirmationModal
          show={showDeleteModal}
          name={deleteMeal?.name}
          handleClose={() => setShowDeleteModal(false)}
          handleConfirm={confirmDelete}
        />
    </React.Fragment>
  );
};

export default Meals;

import { axiosBaseQuery } from "../../interceptor/axiosBaseQuery";
import config from "../../../config/config";
import { createApi } from "@reduxjs/toolkit/query/react";

export const ingredientApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/ingredients` }),
  endpoints: () => ({}),
  reducerPath: "ingredientApi",
  keepUnusedDataFor: 30,
  tagTypes: ["Ingredients", "Ingredient Addons", "Ingredient"],
});
import { mealApi } from ".";

export const addIngredientToMeal = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    addIngredientToMeal: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/${id}/ingredients/add`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: ["Meal"],
    }),
  }),
});

export const { useAddIngredientToMealMutation } = addIngredientToMeal;

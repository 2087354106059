import { ingredientApi } from ".";

export const getIngredients = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    getIngredients: builder.query({
      query: () => ({
        url: ``,
      }),
      providesTags: ["Ingredients"],
    }),
  }),
});

export const { useGetIngredientsQuery } = getIngredients;
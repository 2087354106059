import { axiosBaseQuery } from "../../interceptor/axiosBaseQuery";
import config from "../../../config/config";
import { createApi } from "@reduxjs/toolkit/query/react";

export const orderApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/admin/orders` }),
  endpoints: () => ({}),
  reducerPath: "orderApi",
  // keepUnusedDataFor: 30,
  tagTypes: ["Orders", "Order"],
});
import { ingredientApi } from ".";

export const addIngredientToAddon = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    addIngredientToAddon: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `/${id}/addon`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: ["Ingredient"],
    }),
  }),
});

export const { useAddIngredientToAddonMutation } = addIngredientToAddon;

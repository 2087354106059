import dayjs from 'dayjs';
import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const MainDetails = ({ order }) => {
  const { user, deliveryInformation, subscription } = order;

  return (
    <Container className="my-4">
      {/* Main Details Section - 3 Columns in One Row */}
      <Row className="mb-4">
        {/* User Information */}
        <Col md={4} className="mb-3">
          <Card>
            <Card.Header as="h5" className="bg-primary text-white">User Information</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item><strong>Name:</strong> {user.firstName} {user.lastName}</ListGroup.Item>
                <ListGroup.Item><strong>Email:</strong> {user.email}</ListGroup.Item>
                <ListGroup.Item><strong>Phone Number:</strong> {user.phone}</ListGroup.Item>
                <ListGroup.Item><strong>Gender:</strong> {user.gender}</ListGroup.Item>
                <ListGroup.Item><strong>Date of Birth:</strong> {dayjs(user.dob).format("DD-MM-YYYY")}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        {/* Delivery Information */}
        <Col md={4} className="mb-3">
          <Card>
            <Card.Header as="h5" className="bg-primary text-white">Delivery Information</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item><strong>Contact Person:</strong> {deliveryInformation.contactName}</ListGroup.Item>
                <ListGroup.Item><strong>Contact Phone:</strong> {deliveryInformation.phone}</ListGroup.Item>
                <ListGroup.Item><strong>Delivery Address:</strong> {deliveryInformation.address}</ListGroup.Item>
                <ListGroup.Item><strong>Delivery State:</strong> {deliveryInformation.state}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        {/* Subscription Information */}
        <Col md={4} className="mb-3">
          <Card>
            <Card.Header as="h5" className="bg-primary text-white">Subscription Information</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item><strong>Subscription Plan:</strong> {subscription.subscriptionPlan.name} {subscription.subscriptionPlan.mealPlan.name}</ListGroup.Item>
                <ListGroup.Item><strong>Number of days:</strong> {subscription.noOfDays} days per week</ListGroup.Item>
                <ListGroup.Item><strong>Start Date:</strong> {dayjs(subscription.startDate).format("DD-MM-YYYY")}</ListGroup.Item>
                <ListGroup.Item><strong>End Date:</strong> {dayjs(subscription.endDate).format("DD-MM-YYYY")}</ListGroup.Item>
                <ListGroup.Item><strong>Course:</strong> {subscription.courses}</ListGroup.Item>
                <ListGroup.Item><strong>Status:</strong> {subscription.status}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MainDetails;

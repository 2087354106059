import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Col, Card, Row, Button } from "react-bootstrap";
import { formatCurrency } from "../../common/utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import DataTableComponent from "../../components/DataTable";
import { useRemoveIngredientFromMealMutation } from "../../api/baseQueries/mealApi/removeIngredientFromMeal";
import { useGetIngredientDetailsQuery } from "../../api/baseQueries/ingredientApi/getIngredientDetails";
import { EditIngredientAddonModal } from "./modal/editIngredientAddonModal";
import { RemoveConfirmationModal } from "./modal/removeConfirmationModal";
import { AddIngredientAddonModal } from "./modal/addIngredientAddonModal";
import { useRemoveIngredientFromAddonMutation } from "../../api/baseQueries/ingredientApi/removeIngredientFromMeal";
import { AddAddonAddonModal } from "./modal/addAddonAddon";

const IngredientDetails = () => {
  const { id } = useParams();
  const { data, error, isLoading } = useGetIngredientDetailsQuery({ id });
  const ingredient = data?.data;

  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const [showAddonModal, setShowAddonModal] = useState(false);
  const handleShowIngredientModal = () => setShowIngredientModal(true);
  const handleCloseIngredientModal = () => setShowIngredientModal(false);
  
  const handleShowAddonModal = () => setShowAddonModal(true);
  const handleCloseAddonModal = () => setShowAddonModal(false);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [selectedIngredient, setSelectedIngredient] = useState({
    id: "",
    name: "",
    qtyInGrams: "",
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [ingredientToDelete, setIngredientToDelete] = useState(null);

  const [removeIngredientFromAddon] = useRemoveIngredientFromAddonMutation();

  const handleEditIngredient = (ingredient) => {
    setSelectedIngredient(ingredient);
    setShowModal(true);
  };

  const handleRemoveIngredient = (ingredient) => {
    setIngredientToDelete(ingredient);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (ingredientToDelete) {
      await removeIngredientFromAddon({
        id,
        ingredient: ingredientToDelete.id,
      });
      setShowDeleteModal(false);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Quantity (grams)",
      selector: (row) => row.qtyInGrams,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleEditIngredient(row)}
            style={{ marginRight: "5px" }} // Add margin-right for spacing
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>{" "}
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => handleRemoveIngredient(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <Col xs="12">
            <Card className="shadow">
              <Card.Body>
                <div className="d-flex justify-content-end mb-3">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <Link to={`/dashboard//${id}/edit`} className="me-3">
                      <Button variant="primary">Edit Addon</Button>
                    </Link>
                    <Button
                      variant="primary"
                      onClick={handleShowIngredientModal}
                      className="me-3"
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: "5px" }}
                      />
                      Add Ingredient
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleShowAddonModal}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: "5px" }}
                      />
                      Add Add on
                    </Button>
                  </div>
                </div>
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p className="text-danger">Error loading meal details</p>
                ) : (
                  <div>
                    <h2 className="mb-4">{ingredient.name}</h2>
                    <p className="mb-3">
                      <strong>Amount:</strong>{" "}
                      {formatCurrency(ingredient.costPrice)}
                    </p>
                    <p className="mb-3">
                      <strong>Quantity in grams:</strong> {ingredient.qtyInGram}
                    </p>
                    <p className="mb-10">
                      <strong>Price per gram:</strong> {ingredient.pricePerGram}
                    </p>

                    <br />

                    <h4 className="mb-3">Ingredients:</h4>
                    <DataTableComponent
                      title=""
                      columns={columns}
                      data={ingredient ? ingredient.ingredients : []}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
      <AddIngredientAddonModal
        show={showIngredientModal}
        handleClose={handleCloseIngredientModal}
        ingredient={ingredient && ingredient}
      />
      <AddAddonAddonModal
        show={showAddonModal}
        handleClose={handleCloseAddonModal}
        ingredient={ingredient && ingredient}
      />
      <EditIngredientAddonModal
        show={showModal}
        handleClose={handleCloseModal}
        meal={ingredient}
        ingredient={selectedIngredient}
      />
      <RemoveConfirmationModal
        show={showDeleteModal}
        name={ingredientToDelete?.name}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={handleConfirmDelete}
      />
    </React.Fragment>
  );
};

export default IngredientDetails;

import { orderApi } from ".";

export const getOrders = orderApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: ``,
      }),
      providesTags: ["Orders"],
    }),
  }),
});

export const { useGetOrdersQuery } = getOrders;
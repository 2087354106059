import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { useAddIngredientToAddonMutation } from "../../../api/baseQueries/ingredientApi/addIngredientToAddon";

export const EditIngredientAddonModal = ({ show, handleClose, meal, ingredient }) => {
  const [addIngredientToAddon] = useAddIngredientToAddonMutation();

  const [showModal, setShowModal] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState({
    id: "",
    name: "",
    qtyInGrams: "",
  });

  useEffect(() => {
    if (ingredient) {
      setSelectedIngredient(ingredient);
    }
  }, [ingredient]);

  const handleEditIngredient = async () => {
    if (selectedIngredient) {
      const payload = {
        component_id: selectedIngredient.id,
        component_qty_in_grams:  parseFloat(selectedIngredient.qtyInGrams).toFixed(2),
      };

      try {
        await addIngredientToAddon({ id: meal?.id, ...payload }).unwrap();
        console.log("Ingredient added successfully");
      } catch (error) {
        console.error("Failed to add ingredient:", error);
      }


      // Close the modal
      handleClose();
    }
  };


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center", fontSize: '16px' }}>Edit ingredient quantity in {meal?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          Note: Quantity MUST be entered in grams. If you have the quantity in
          ML, L or any other measurement, please convert it to grams and enter
          the correct quantity in grams.
        </Alert>
        <Form>
          <Form.Group className="mb-3" controlId="formIngredientName">
            <Form.Label>Ingredient Name</Form.Label>
            <Form.Control
              type="text"
              readOnly
              value={selectedIngredient.name}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formIngredientQty">
            <Form.Label>Quantity (grams)</Form.Label>
            <Form.Control
              type="number"
              value={selectedIngredient.qtyInGrams}
              onChange={(e) =>
                setSelectedIngredient({
                  ...selectedIngredient,
                  qtyInGrams: e.target.value,
                })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleEditIngredient}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

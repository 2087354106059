import { ingredientApi } from ".";

export const getIngredientAddons = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    getIngredientAddons: builder.query({
      query: () => ({
        url: `/addons/all`,
      }),
      providesTags: ["Ingredient Addons"],
    }),
  }),
});

export const { useGetIngredientAddonsQuery } = getIngredientAddons;
import { dietCategoryApi } from ".";

export const getDietCategories = dietCategoryApi.injectEndpoints({
  endpoints: (builder) => ({
    getDietCategories: builder.query({
      query: () => ({
        url: ``,
      }),
      providesTags: ["Diet Categories"],
    }),
  }),
});

export const { useGetDietCategoriesQuery } = getDietCategories;
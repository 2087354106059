import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetMealDetailsQuery } from "../../api/baseQueries/mealApi/getMealDetails";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Col, Row, Button, Form, Card } from "react-bootstrap";
import { useGetIngredientsQuery } from "../../api/baseQueries/ingredientApi/getIngredients";
import Select from "react-select";
import DataTableComponent from "../../components/DataTable";
import { formatCurrency } from "../../common/utils";
import { useGetDietCategoriesQuery } from "../../api/baseQueries/dietCategoryApi/getDietCategories";
import { useUpdateMealMutation } from "../../api/baseQueries/mealApi/updateMeal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditMeal = () => {
  const { id } = useParams();
  const {
    data: mealData,
    error: mealError,
    isLoading: mealLoading,
  } = useGetMealDetailsQuery({ id });

  const {
    data: dietCategoriesData,
    error: dietCategoriesError,
    isLoading: dietCategoriesLoading,
  } = useGetDietCategoriesQuery();

  const meal = mealData?.data;
  const dietCategories = dietCategoriesData?.data?.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const [formData, setFormData] = useState({
    name: "",
    serving_size: "",
    description: "",
    calories_per_serving: 0,
    fat: 0,
    protein: 0,
    carbs: 0,
    fibre: 0,
    meal_course: [],
    diet_category_ids: [],
    ingredients: [],
  });

  useEffect(() => {
    if (meal) {
      setFormData({
        name: meal.name,
        serving_size: meal.servingSize,
        description: meal.description,
        calories_per_serving: meal.caloriesPerServing,
        fat: meal.fat,
        protein: meal.protein,
        carbs: meal.carbs,
        fibre: meal.fibre,
        meal_course: JSON.parse(meal.mealCourse)?.map((course) => ({
          value: course,
          label: course,
        })),
        diet_category_ids: meal.dietCategories?.map((category) => ({
          value: category.id,
          label: category.name,
        })),
        ingredients: meal.mealIngredients?.map((ingredient) => ingredient.id),
      });
    }
  }, [meal]);

  const [updateMeal, { isLoading: isUpdating, error: updateError }] =
    useUpdateMealMutation();

  const handleSave = async () => {
    const updatedMeal = {
      ...formData,
      meal_course: formData.meal_course.map((course) => course.value), // convert to array of strings
      diet_category_ids: formData.diet_category_ids.map((category) => {
        return {
          diet_category_id: category.value,
        };
      }), // convert to array of IDs
    };

    // console.log(updatedMeal);

    try {
      await updateMeal({ id, ...updatedMeal }).unwrap();
      toast.success("Meal updated successfully!");
    } catch (err) {
      toast.error("Failed to update meal!");
      console.error("Failed to update meal:", err);
    }
  };

  const handleMultiSelectChange = (selectedOptions, field) => {
    setFormData({
      ...formData,
      [field]: selectedOptions
        ? selectedOptions?.map((option) => ({
            value: option.value,
            label: option.label,
          }))
        : [],
    });
  };

  const mealCourseOptions = [
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Dinner", label: "Dinner" },
    { value: "Soups", label: "Soups" },
    { value: "Snack", label: "Snack" },
  ];

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Quantity (grams)",
      selector: (row) => row.qtyInGrams,
      sortable: true,
    },
  ];

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <ToastContainer />
        <Row className="g-3">
          <Col xs="7">
            <Card
              className="edit-meal-card"
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <h2>Edit Meal</h2>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicServingSize">
                    <Form.Label>Serving Size</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter serving size"
                      value={formData.serving_size}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          serving_size: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter description"
                      value={formData.description}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          description: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFat">
                    <Form.Label>Fat (in grams)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter fat in grams"
                      value={formData.fat}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fat: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formCarbs">
                    <Form.Label>Carbs (in grams)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter carbs in grams"
                      value={formData.carbs}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          carbs: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formProtein">
                    <Form.Label>Protein (in grams)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter protein in grams"
                      value={formData.protein}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          protein: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formFibre">
                    <Form.Label>Fibre (in grams)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter fibre in grams"
                      value={formData.fibre}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          fibre: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formMealCourse">
                    <Form.Label>Meal Course</Form.Label>
                    <Select
                      isMulti
                      options={mealCourseOptions}
                      value={formData.meal_course}
                      onChange={(selectedOptions) =>
                        handleMultiSelectChange(selectedOptions, "meal_course")
                      }
                      placeholder="Select Meal Course"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDietCategory">
                    <Form.Label>Diet Category</Form.Label>
                    <Select
                      isMulti
                      options={dietCategories}
                      value={formData.diet_category_ids}
                      onChange={(selectedOptions) =>
                        handleMultiSelectChange(
                          selectedOptions,
                          "diet_category_ids"
                        )
                      }
                      placeholder="Select Diet Category"
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={handleSave}>
                    Save
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="5">
            <Card
              className="meal-preview-card"
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
            >
              <Card.Body>
                <h2>Preview</h2>
                <div>
                  <p>
                    <strong>Name:</strong> {formData.name}
                  </p>
                  <p>
                    <strong>Amount:</strong> {formatCurrency(formData.amount)}
                  </p>
                  <p>
                    <strong>Serving Size:</strong> {formData.serving_size}
                  </p>
                  <p>
                    <strong>Description:</strong> {formData.description}
                  </p>
                  <p>
                    <strong>Calories Per Serving:</strong>{" "}
                    {formData.calories_per_serving}
                  </p>
                  <p>
                    <strong>Fat:</strong> {formData.fat}
                  </p>
                  <p>
                    <strong>Protein:</strong> {formData.protein}
                  </p>
                  <p>
                    <strong>Carbs:</strong> {formData.carbs}
                  </p>
                  <p>
                    <strong>Fibre:</strong> {formData.fibre}
                  </p>
                  <p>
                    <strong>Meal Course:</strong>{" "}
                    {formData.meal_course
                      ?.map((course) => course?.label)
                      .join(", ")}
                  </p>
                  <p>
                    <strong>Diet Category:</strong>{" "}
                    {formData.diet_category_ids
                      ?.map((category) => category?.label)
                      .join(", ")}
                  </p>
                  <p>
                    <strong>Ingredients:</strong>
                  </p>
                  <DataTableComponent
                    title=""
                    columns={columns}
                    data={meal ? meal.mealIngredients : []}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EditMeal;

import { ingredientApi } from ".";

export const createIngredient = ingredientApi.injectEndpoints({
  endpoints: (builder) => ({
    createIngredient: builder.mutation({
      query: (payload) => ({
        url: ``,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: ["Ingredients"],
    }),
  }),
});

export const { useCreateIngredientMutation } = createIngredient;

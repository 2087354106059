import { mealApi } from ".";

export const removeIngredientFromMeal = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    removeIngredientFromMeal: builder.mutation({
      query: ({ id, ingredient }) => ({
        url: `/${id}/ingredients/${ingredient}`,
        method: 'DELETE',
      }),
      invalidatesTags: ["Meal"],
    }),
  }),
});

export const { useRemoveIngredientFromMealMutation } = removeIngredientFromMeal;

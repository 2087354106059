import React, { useState } from "react";
import { Col, Card, Row, Button } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import DataTableComponent from "../../components/DataTable";
import { useGetIngredientAddonsQuery } from "../../api/baseQueries/ingredientApi/getIngredientAddons";
import { formatCurrency } from "../../common/utils";
import CreateIngredientAddonModal from "./modal/createIngredientAddonModal";

export default function IngredientAddons() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetIngredientAddonsQuery();
  const ingredients = data?.data;
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleView = (row) => {
    navigate(`/dashboard/ingredients/${row.id}`);
  };

  const columns = [
    { name: "Name", selector: (row) => row.name, sortable: true },
    {
      name: "Cost Price",
      selector: (row) => formatCurrency(row.costPrice),
      sortable: true,
    },
    {
      name: "Quantity in Grams",
      selector: (row) => row.qtyInGram + "g",
      sortable: true,
    },
    {
      name: "Price Per Gram",
      selector: (row) => formatCurrency(row.pricePerGram),
      sortable: true,
    },
    { name: "Status", selector: (row) => row.status, sortable: true },
    {
      name: "Actions",
      cell: (row) => (
        <Button variant="primary" onClick={() => handleView(row)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Header onSkin={false} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ingredients
              </li>
            </ol>
          </div>
        </div>

        <Row className="g-3">
          <Col xs="12">
            <Card className="card-one">
              <Card.Body>
                <Button
                  className="float-end my-3"
                  onClick={handleShowModal}
                >
                  Create Ingredient Add-on
                </Button>

                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading ingredients</p>
                ) : (
                  <DataTableComponent
                    title={"List of Ingredients Add-ons"}
                    data={ingredients}
                    columns={columns}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>

      <CreateIngredientAddonModal show={showModal} handleClose={handleCloseModal} />
    </React.Fragment>
  );
}

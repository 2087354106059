import { orderApi } from ".";

export const getOrder = orderApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: ({ id }) => ({
        url: `/${id}`,
      }),
      providesTags: ["Order"],
    }),
  }),
});

export const { useGetOrderQuery } = getOrder;
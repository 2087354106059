import { axiosBaseQuery } from "../../interceptor/axiosBaseQuery";
import config from "../../../config/config";
import { createApi } from "@reduxjs/toolkit/query/react";

export const mealApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/meals` }),
  endpoints: () => ({}),
  reducerPath: "mealApi",
  keepUnusedDataFor: 30,
  tagTypes: ["Meals", "Meal"],
});
import { mealApi } from ".";

export const updateMeal = mealApi.injectEndpoints({
  endpoints: (builder) => ({
    updateMeal: builder.mutation({
      query: ({ id, action, ...payload }) => ({
        url: `/${id}`, 
        method: 'PUT',
        data: payload,
        params: { action },
      }),
      invalidatesTags: ["Meal"],
    }),
  }),
});

export const { useUpdateMealMutation } = updateMeal;
